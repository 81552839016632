































import { Component, Prop, Vue, Emit } from "vue-property-decorator";

@Component
export default class DictionaryAliasInput extends Vue {
  @Prop({ default: () => [], type: [Array] }) value!: string[];

  searchInput: null | string = null;

  clearSearchInput(): void {
    this.searchInput = null;
  }

  @Emit("input")
  handleOnInput(value: string[]): string[] {
    return value;
  }

  handleOnKeydown(event: KeyboardEvent) {
    if (event.code === "Enter") {
      this.handleOnInput([...this.value, this.searchInput as string]);
      this.clearSearchInput();
    }
  }
}
