














import { Vue, Component, Prop } from "vue-property-decorator";
import { shortenText } from "@/utils/string";

@Component
export default class LongTextWithTooltip extends Vue {
  @Prop({ required: true, type: String }) text!: string;
  @Prop({ default: 99, type: Number }) length!: number;

  get isTextLonger(): boolean {
    return this.text.length > this.length;
  }

  get shortenedText(): string {
    return shortenText(this.text, this.length);
  }
}
