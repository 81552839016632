





































































import { Component, Mixins, Prop } from "vue-property-decorator";
import { ITableHeader } from "@/components/data-table/types";
import TranslatedEnumListsMixin from "@/mixins/TranslatedEnumListsMixin";
import SearchProfile from "@/entity/SearchProfile";
import SearchProfileService from "@/services/SearchProfileService";
import LongTextWithTooltip from "@/components/LongTextWithTooltip.vue";
import { ALL_ITEMS_PER_PAGE, DEFAULT_MONEY_RANGE_VALUES } from "@/constants";
import Contact from "@/entity/Contact";
import SearchProfileForm from "@/components/contact/searchProfileForm.vue";
import DialogWrapper from "@/components/common/DialogWrapper.vue";
import { getPriceRangeForSlider } from "@/utils/getPriceRangeForSlider";
import { displayValue } from "@/utils/analytics";

enum DialogStatusEnum {
  ADDING,
  EDITING,
}

@Component({
  components: {
    LongTextWithTooltip,
    SearchProfileForm,
    DialogWrapper,
  },
})
export default class ContactSearchProfilesView extends Mixins(
  TranslatedEnumListsMixin
) {
  @Prop({ required: true, type: Object }) contact!: Contact;

  isFetching = false;
  isDeleting = false;
  isUpdatingOrAdding = false;

  searchProfiles: SearchProfile[] = [];

  isDialogOpened = false;
  dialogStatus = DialogStatusEnum["ADDING"];

  dialogEntityId = 0;

  selectedIndustries: number[] = [];
  selectedSubIndustries: number[] = [];
  selectedRegion = 0;
  selectedRange: [number, number] = [0, 0];
  note = "";

  get headers(): ITableHeader[] {
    return [
      {
        text: this.$tc("priceFrom", 1),
        value: "priceFrom",
      },
      {
        text: this.$tc("priceTo", 1),
        value: "priceTo",
      },
      {
        text: this.$tc("region", 1),
        value: "region.name",
      },
      {
        text: this.$tc("industry", 1),
        value: "branches",
      },
      {
        text: this.$tc("subIndustry", 2),
        value: "subbranches",
      },
      {
        text: this.$tc("comment", 1),
        value: "message",
      },
      {
        text: this.$tc("actions", 2),
        value: "actions",
        sortable: false,
      },
    ];
  }

  async deleteSearchProfile(profileId: number): Promise<void> {
    try {
      this.isDeleting = true;

      const didConfirm = await this.$confirm(this.$tc("confirmations.delete"));

      if (!didConfirm) {
        return;
      }

      await SearchProfileService.delete(profileId);
      this.fetchSearchProfiles();
    } catch (e) {
      this.$snackbarError(this.$tc("apiErrors.unableToDelete"));
    } finally {
      this.isDeleting = false;
    }
  }

  async editSearchProfile(profileId: number): Promise<void> {
    this.dialogStatus = DialogStatusEnum["EDITING"];
    this.dialogEntityId = profileId;
    const searchProfile = this.dialogSearchProfile;
    this.selectedIndustries =
      searchProfile?.branches.map((item) => item.id) || [];
    this.selectedSubIndustries =
      searchProfile?.subbranches.map((item) => item.id) || [];
    this.selectedRegion = searchProfile?.regionId || 0;

    this.selectedRange = getPriceRangeForSlider(
      searchProfile?.priceFrom,
      searchProfile?.priceTo
    );
    this.note = searchProfile?.message || "";
    this.isDialogOpened = true;
  }

  async addSearchProfile(): Promise<void> {
    this.dialogStatus = DialogStatusEnum["ADDING"];
    this.isDialogOpened = true;
  }

  closeDialog() {
    this.isDialogOpened = false;
    this.resetFields();
  }

  resetFields() {
    this.selectedIndustries = [];
    this.selectedSubIndustries = [];
    this.selectedRegion = 0;
    this.selectedRange = [0, 0];
    this.note = "";
  }

  async onFormSuccess() {
    try {
      this.isUpdatingOrAdding = true;
      const searchProfile = this.dialogSearchProfile;
      const contactId = Number(this.$route.params.contactId);
      const payload = {
        priceFrom: DEFAULT_MONEY_RANGE_VALUES[this.selectedRange[0]],
        priceTo: DEFAULT_MONEY_RANGE_VALUES[this.selectedRange[1]],
        regionId: this.selectedRegion,
        message: this.note,
        branchIds: this.selectedIndustries.join(","),
        subbranchIds: this.selectedSubIndustries.join(","),
      };
      if (this.dialogStatus === DialogStatusEnum["ADDING"]) {
        await SearchProfileService.add(contactId, { ...payload, contactId });
      } else if (
        searchProfile?.id &&
        this.dialogStatus === DialogStatusEnum["EDITING"]
      ) {
        await SearchProfileService.update(searchProfile?.id, {
          ...payload,
          id: searchProfile.id,
          contactId,
        });
      }
      await this.fetchSearchProfiles();
      this.isDialogOpened = false;
      this.resetFields();
    } catch (error) {
      console.log(error);
    } finally {
      this.isUpdatingOrAdding = false;
    }
  }

  get dialogSearchProfile() {
    return (
      this.searchProfiles.find((item) => item.id === this.dialogEntityId) ||
      null
    );
  }

  async fetchSearchProfiles(): Promise<void> {
    try {
      this.isFetching = true;
      this.searchProfiles = await SearchProfileService.find({
        itemsPerPage: ALL_ITEMS_PER_PAGE,
        filterBy: {
          contactId: this.contact.id,
        },
      });
      this.searchProfiles = this.searchProfiles.map((item) => {
        return {
          ...item,
          branches: item.branches || [],
          subbranches: item.subbranches || [],
        };
      });
      console.log(this.searchProfiles);
    } catch (e) {
      this.$snackbarError(this.$tc("apiErrors.unableToLoad"));
    } finally {
      this.isFetching = false;
    }
  }

  formatValue(value: number) {
    return displayValue(value);
  }

  async created(): Promise<void> {
    await this.fetchSearchProfiles();
  }
}
