




















































































import { Component, Prop, Mixins, PropSync } from "vue-property-decorator";
import FormCard from "@/components/form/FormCard.vue";
import BaseForm from "@/components/form/BaseForm";
import RulesMixin from "@/mixins/RulesMixin";
import DictionaryAliasInput from "@/components/dictionaries/DictionaryAliasInput.vue";
import { State } from "vuex-class";
import SearchProfile from "@/entity/SearchProfile";
import { ReferenceItem } from "@/services/types";
import RangeSlider from "../form/input/RangeSlider.vue";
import { DEFAULT_MONEY_RANGE_VALUES, CURRENCY } from "@/constants";
import { formatPriceRange } from "@/utils/string";

@Component({
  components: {
    FormCard,
    DictionaryAliasInput,
    RangeSlider,
  },
})
export default class SearchProfileForm extends Mixins(BaseForm, RulesMixin) {
  @Prop({ required: false, type: Object }) searchProfile!: SearchProfile;
  @Prop({ required: true, type: Boolean }) loading!: boolean;
  @State("regions", { namespace: "selectOptions" }) regions!: ReferenceItem[];
  @State("industries", { namespace: "selectOptions" })
  industries!: ReferenceItem[];
  @State("subIndustries", { namespace: "selectOptions" })
  subIndustries!: ReferenceItem[];
  @PropSync("selectedRegion", { type: Number, required: true })
  selectedRegionLocal!: number;
  @PropSync("selectedIndustries", { type: Array, required: true })
  selectedIndustriesLocal!: ReferenceItem[];
  @PropSync("selectedSubIndustries", { type: Array, required: true })
  selectedSubIndustriesLocal!: ReferenceItem[];
  @PropSync("selectedRange", { type: Array, required: true })
  selectedRangeLocal!: [number, number];
  @PropSync("note", { type: String, required: true })
  noteLocal!: string;

  data: SearchProfile = {
    id: undefined,
    branches: [],
    subbranches: [],
    contactId: 0,
    priceFrom: 0,
    priceTo: 0,
    region: null,
    regionId: null,
  };

  get rangePrice(): string {
    if (this.selectedRangeLocal) {
      return formatPriceRange(
        DEFAULT_MONEY_RANGE_VALUES[this.selectedRangeLocal[0]],
        DEFAULT_MONEY_RANGE_VALUES[this.selectedRangeLocal[1]],
        CURRENCY
      );
    }
    return "";
  }

  reset(name: string) {
    (this.$refs[name] as any).lazySearch = "";
  }
}
