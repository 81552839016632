import Contact from "@/entity/Contact";
import ProjectBuyer from "@/entity/ProjectBuyer";
import Company from "./Company";

export enum ProjectType {
  transaction = "transaction",
  valuation = "valuation",
}

export enum ProjectStatusTransaction {
  sold = "projectstatus.sold",
  closed = "projectstatus.closed",
  expired = "projectstatus.expired",
  contract = "projectstatus.contract",
  preparation = "projectstatus.preparation",
  marketing = "projectstatus.marketing",
}

export enum ProjectStatusValuation {
  evaluating = "projectstatus.evaluating",
  closed = "projectstatus.closed",
}

export const projectStatusColor = {
  [ProjectStatusTransaction.sold]: "green",
  [ProjectStatusTransaction.preparation]: "light-blue",
  [ProjectStatusTransaction.marketing]: "blue darken-4",
  [ProjectStatusValuation.evaluating]: "blue darken-4",
  [ProjectStatusValuation.closed]: "black",
  [ProjectStatusTransaction.closed]: "black",
  [ProjectStatusTransaction.expired]: "grey",
  [ProjectStatusTransaction.contract]: "yellow",
  Deaktiviert: "black",
};

export interface ProjectOperations {
  hasConsultantRights: boolean;
  canEditSales: boolean;
  canCloseProject: boolean;
  canUploadFinanceData: boolean;
}

export default interface Project {
  id?: number;
  projectContactId?: number | null;
  consultantId?: number | null;
  consultantSecondId?: number | null;
  note?: string | null;
  salesPricePublished: number | null;
  potentialWin: number | null;
  initialCost: number | null;
  marketingBudget: number | null;
  teaserUrl: string | null;
  teaserTitle: string | null;
  startingDate?: null | number | string;
  status: ProjectStatusTransaction | ProjectStatusValuation;
  historyUpdatedAt: number | null;
  projectContact: Company;
  contact?: Contact | null;
  exposeFileExists: boolean;
  projectType: ProjectType;
  valuationDate: null | number;
  valuationReason: null | string;
  creationDate: null | number;
  projectOperations: null | ProjectOperations;
  projectRating: number | null;
  isDisabled: boolean;
}

export interface Prospect extends Project {
  prospectiveBuyer: ProjectBuyer;
}

export interface ProjectWithConsultantNames extends Project {
  consultantName: string;
  consultantSecondName: string;
}

export interface FinanceYears {
  firstYear: number;
  lastYear: number;
}

export const getRegionNameFromProject = (project: Project): string => {
  return project?.projectContact?.region?.name ?? "";
};
