import { Vue, Component } from "vue-property-decorator";
import { OptionItem } from "@/services/types";
import { capitalizeStart } from "@/utils/string";
import { UserRole } from "@/entity/User";
import {
  ProjectStatusValuation,
  ProjectStatusTransaction,
} from "@/entity/Project";
import { BuyerStatus } from "@/entity/ProjectBuyer";
import { HistoryTypes } from "@/entity/History";
import { NotificationType } from "@/entity/Notification";

interface TranslatedEnums {
  userRoles: OptionItem[];
  projectStatusesTransaction: OptionItem[];
  projectStatusesValuation: OptionItem[];
  buyerStatuses: OptionItem[];
  boolOptions: OptionItem[];
  historyTypes: OptionItem[];
  notificationTypes: OptionItem[];
}

@Component
export default class TranslatedEnumListsMixin extends Vue {
  userRoles(): OptionItem[] {
    return Object.keys(UserRole).map((status) => {
      return {
        name: capitalizeStart(
          this.$tc("userRole." + UserRole[status as keyof typeof UserRole], 1)
        ),
        value: UserRole[status as keyof typeof UserRole],
      };
    });
  }

  projectStatusesTransaction(): OptionItem[] {
    return Object.keys(ProjectStatusTransaction).map((status) => {
      return {
        name: this.$tc(
          ProjectStatusTransaction[
            status as keyof typeof ProjectStatusTransaction
          ],
          1
        ),
        value:
          ProjectStatusTransaction[
            status as keyof typeof ProjectStatusTransaction
          ],
      };
    });
  }

  projectStatusesValuation(): OptionItem[] {
    return Object.keys(ProjectStatusValuation).map((status) => {
      return {
        name: this.$tc(
          ProjectStatusValuation[status as keyof typeof ProjectStatusValuation],
          1
        ),
        value:
          ProjectStatusValuation[status as keyof typeof ProjectStatusValuation],
      };
    });
  }

  buyerStatuses(): OptionItem[] {
    return Object.keys(BuyerStatus).map((status) => {
      return {
        name: this.$tc(BuyerStatus[status as keyof typeof BuyerStatus], 1),
        value: BuyerStatus[status as keyof typeof BuyerStatus],
      };
    });
  }

  boolOptions(): OptionItem[] {
    return [
      {
        name: this.$tc("yes"),
        value: true,
      },
      {
        name: this.$tc("no"),
        value: false,
      },
    ];
  }

  historyTypes(): OptionItem[] {
    return Object.keys(HistoryTypes).map((type) => {
      return {
        name: this.$tc(
          "historyTypes." + HistoryTypes[type as keyof typeof HistoryTypes],
          1
        ),
        value: HistoryTypes[type as keyof typeof HistoryTypes],
      };
    });
  }

  notificationTypes(): OptionItem[] {
    return Object.keys(NotificationType).map((type) => {
      return {
        name: this.$tc(
          "historyTypes." +
            NotificationType[type as keyof typeof NotificationType],
          1
        ),
        value: NotificationType[type as keyof typeof NotificationType],
      };
    });
  }

  get translatedEnums(): TranslatedEnums {
    return {
      userRoles: this.userRoles(),
      projectStatusesTransaction: this.projectStatusesTransaction(),
      projectStatusesValuation: this.projectStatusesValuation(),
      buyerStatuses: this.buyerStatuses(),
      boolOptions: this.boolOptions(),
      historyTypes: this.historyTypes(),
      notificationTypes: this.notificationTypes(),
    };
  }
}
