import SearchProfile, { SearchProfileForDB } from "@/entity/SearchProfile";
import Axios from "axios";
import filterQueryParams from "@/services/filterQueryParams";
import { TableQuery } from "@/components/data-table/types";

export default class SearchProfileService {
  static async find(query: TableQuery): Promise<SearchProfile[]> {
    const response = await Axios.get("/api/v2/searchProfiles", {
      params: {
        ...filterQueryParams(query),
      },
    });
    return response.data.content;
  }

  static async delete(searchProfileId: number): Promise<void> {
    const response = await Axios.delete(
      `/api/v2/searchProfiles/${searchProfileId}`
    );
    return response.data;
  }

  static async update(
    searchProfileId: number,
    searchProfile: SearchProfileForDB
  ): Promise<void> {
    const response = await Axios.put(
      `/api/v2/searchProfiles/${searchProfileId}`,
      searchProfile
    );
    return response.data;
  }

  static async add(
    contactId: number,
    searchProfile: SearchProfileForDB
  ): Promise<void> {
    const response = await Axios.post(
      `/api/v2/searchProfiles/contact/${contactId}`,
      searchProfile
    );
    return response.data;
  }
}
